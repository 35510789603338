const scaleElms = document.querySelectorAll('.js_scale');
const scaleMin = 1;
const scaleMax = 1.2;

const scaleFunc = (el) => {
	const _top = el.getBoundingClientRect().top;
	const n = (_top - window.innerHeight) * -1.0001 + (_top - window.innerHeight) + 1;
	if(n < scaleMin) {
		el.style.transform = 'scale(' + scaleMin + ')';
		return;
	}
	if(n > scaleMax) {
		el.style.transform = 'scale(' + scaleMax + ')';
		return;
	}
	el.style.transform = 'scale(' + n + ')';
};

const scroll2Scale = () => {
	scaleElms.forEach(el => {
		scaleFunc(el);
	});
};

window.addEventListener('load', scroll2Scale, false);
window.addEventListener('scroll', scroll2Scale, false);
